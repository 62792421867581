<script setup>    
    import Video from '@/components/Video.vue';
    
    const props = defineProps(['step']);

    function alert() {
        let alert = false;
        if (props.step.alert) {
            alert = props.step.alert;
        }
        if (typeof alert === 'object') {
            alert = alert.value;
        }
        return alert;
    }    
</script>

<template>
    <Video v-if="step.video" :title="step.video.title" :yt_id="step.video.yt_id" />
    <p class="step-description" v-if="step.description">{{ step.description }}</p>
    <div class="alert alert-primary" v-if="alert()" v-html="alert()"></div>
</template>