import { computed, watch, onBeforeMount } from 'vue';
import { Helpers } from '@/helpers';

export function useDepthDependencies(data) {
    onBeforeMount(() => {
        // loop through each step and add a computed disabled property to any options that require specific depths
        Object.entries(data.steps).forEach((kv) => {
            const step = kv[1];

            if (step.getDepthFromStepId) {
                // add a computed disabled property to each option
                step.options.forEach((option) => {
                    option.disabled = computed(() => {
                        return data.steps[step.getDepthFromStepId].rulers[0].current.value < option.minDepth;
                    });

                    // add watch to the new property to see if we need to change the currently selected option
                    watch(option.disabled, () => {
                        if (option.disabled.value && step.selected.value.id == option.id) {
                            step.selected.value = Helpers.getDefaultOption(step);
                        }
                    });
                });

                // add a computed alert property to the step so we can alert the user if the window isn't deep enough for some options
                step.alert = computed(() => {
                    const hasDisabledOptions = !step.options.every((option) => {
                        return !option.disabled.value;
                    });

                    if (hasDisabledOptions)
                        return 'Some options are disabled because they require a deeper mounting depth.';
                    else
                        return '';
                });
            }
        });
    });
}