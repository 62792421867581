import { ref, onBeforeMount } from 'vue'
import { useBackend } from '@/composables/Backend';

export function useCurrentUser(onLoggedIn, onNotLoggedIn) {
  const backend = useBackend();

  // properties
  const isLoggedIn = ref(false);
  const firstName = ref('');
  const lastName = ref('');
  const email = ref('');
  const isTradeProfessional = ref(false);
  const since = ref(null);
  
  // functions
  function doLogin(username, password, onSuccess, onError) {
    backend.login(username, password)
      .then(result => {
        if (result.error) {
          if (onError) {
            onError(result.error)
          }
        }
        else {
          isLoggedIn.value = true;
          firstName.value = result.firstName;
          lastName.value = result.lastName;
          email.value = result.email;

          if (onSuccess) {
            onSuccess({ firstName, lastName, email, isTradeProfessional, since })
          }
        }
      });
  }

  function doLogout(onSuccess, onError) {
    backend.logout()
      .then(result => {
        if (result && result.status && result.status == "success") {
          if (onSuccess) {
            onSuccess();
          }          
        }
        else {
          if (onError) {
            onError();
          }
        }
      });
  }

  function doUpdate(newFirstName, newLastName, newEmail, onSuccess, onError) {
    backend.updateAccountInfo(newFirstName.value, newLastName.value, newEmail.value)
        .then(result => {
          if (result) {
            // request was successfully submitted, so check for returned error
            if (result.error) {
              if (onError) {
                onError(result.message);
              }
            }
            else {
              firstName.value = result.firstName;
              lastName.value = result.lastName;
              email.value = result.email;

              if (onSuccess) {
                onSuccess({ firstName, lastName, email, isTradeProfessional, since });
              }
            }
          }
          else {
            // something went wrong with the request
            if (onError) {
              onError("An unexpected error occurred.");
            }
          }
        });
  }

  // hooks
  onBeforeMount(() => {
    // see if user is logged in
    backend.getAccountInfo()
      .then(result => {
        if (result.error) {
          if (onNotLoggedIn) {
            onNotLoggedIn(result.error);
          }
        } else if  (result === false) {
          onNotLoggedIn(result.error);
        }
        else {
          // user is logged in          
          isLoggedIn.value = true;
          firstName.value = result.firstName;
          lastName.value = result.lastName;
          email.value = result.email;
          isTradeProfessional.value = result.isTradeProfessional;
          since.value = result.since;

          if (onLoggedIn) {
            onLoggedIn({ firstName, lastName, email, isTradeProfessional, since });
          }
        }
      });
  });

  return {
    isLoggedIn,
    firstName,
    lastName,
    email,
    isTradeProfessional,
    since,
    doLogin,
    doLogout,
    doUpdate
  };
}