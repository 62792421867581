<script setup>
    import { onMounted, nextTick, watch } from 'vue'

    const props = defineProps(['step', 'selectedMount']);
    const emit = defineEmits(['depth-pic-clicked']);
    
    watch(
        () => props.step?.selected,
        async (newVal) => {
            if (newVal?.value) {
                await nextTick();
                setupLinks();
            }
        },
        { deep: true }
    );

    function setupLinks() {
        const links = document.getElementsByClassName('window-depth-pic-link');
        
        Array.from(links).forEach(link => {
            link.addEventListener('click', () => {
                const alertImage = props.step?.selected.value.alertImage;
                if (alertImage) {
                    emit('depth-pic-clicked', alertImage);
                }
            });
        });
    } 

    onMounted(async () => {
        await nextTick();
        setupLinks();
    });
</script>

<template>    
    <p class="option-note" v-if="step.selected.value.description" v-html="step.selected.value.description"></p>
    <div class="alert alert-primary alert-below-text help-text-mount inside-mount" v-if="step.selected.value.alert"><span class="help-text__inner" v-html="step.selected.value.alert"></span></div>
    <!-- @todo - remove -->
    <!-- <div class="alert alert-primary alert-below-text help-text-mount inside-mount" v-if="step.selected.value.alert && props.selectedMount != 'outside-mount'"><span class="help-text__inner" v-html="step.selected.value.alert"></span></div>
    <div class="alert alert-primary alert-below-text help-text-mount inside-mount" v-else-if="step.selected.value.alert && props.selectedMount === 'outside-mount'"><span class="help-text__inner" v-html="step.selected.value.alert"></span></div> -->
</template>