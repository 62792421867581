<script setup>
    import { onMounted } from 'vue'
    import ButtonGroupLayout from '@/components/OptionLayouts/ButtonGroup.vue';
    import Ruler from '@/components/Ruler.vue';
    import FractionSelect from '@/components/FractionSelect.vue'
    //import Fraction from '@/fraction.js';

    //const props = 
    defineProps(['step', 'shouldShowRuler']);    
    const emit = defineEmits(['select-option', 'length-changed']);

    function onSelectOption(step, selected) {
        // re-emitting event since they don't bubble up naturally in vue
        emit('select-option', step, selected);
    }    

    function onRulerChanged(step, ruler, newValue) {
        emit('length-changed', step, ruler, newValue);
    }

    /* function onFractionChanged(combo) {

    } */

    /* let fraction_options = [ {text: '--', value: 0} ];
    Fraction.values.forEach((fraction) => {
        fraction_options.push(fraction);
    }); */

    onMounted(() => {        
        // setup combo
        //const fractionCombo = document.getElementById(`${props.step.id}-fraction`);
        //fractionCombo.value = Fraction.splitOffFraction(Fraction.format.to(props.step.rulers[0].current.value));

        // wire up change events
        /* function onLengthChanged(sliderValue, comboValue) {
            emit('length-changed', props.step, sliderValue, comboValue);
        }
        
        fractionCombo.onchange = function() {
            onLengthChanged(Fraction.format.from(slider.get()), fractionCombo.value);            
        }; */
    });
</script>

<template>
    <ButtonGroupLayout :step="step" @select-option="onSelectOption"></ButtonGroupLayout>
    <div :style="!shouldShowRuler ? 'display:none' : ''">
        <div class="slider" >
            <div class="scale"><span>{{ step.rulers[0].min.value }} IN</span><strong>LENGTH</strong><span>{{ step.rulers[0].max.value }} IN</span></div>
            <Ruler :step="step" :ruler="step.rulers[0]" @ruler-changed="onRulerChanged"></Ruler>
        </div>
        <FractionSelect :step="step" :ruler="step.rulers[0]"></FractionSelect>
        <!-- <BFormSelect :id="`${step.id}-fraction`" :options="fraction_options" @on-change="onFractionChanged" /> -->
    </div>
</template>