<script setup>
    import ButtonGroupLayout from '@/components/OptionLayouts/ButtonGroup.vue';
    import Ruler from '@/components/Ruler.vue';
    import Fraction from '@/fraction.js';

    defineProps(['step']);    
    const emit = defineEmits(['select-option', 'depth-changed']);

    function onSelectOption(step, selected) {
        // re-emitting event since they don't bubble up naturally in vue
        emit('select-option', step, selected);
    }

    function onRulerChanged(step, ruler, newValue) {
        emit('depth-changed', step, ruler, newValue);
    }

    function formatDepthToFraction(value) {
        const splitDepth = Fraction.split(value);
        const fraction = Fraction.splitOffFraction(value);
        return `${splitDepth.whole}${fraction ? fraction.text : ''}`;
    }
</script>

<template>
    <ButtonGroupLayout :step="step" @select-option="onSelectOption"></ButtonGroupLayout>
    <div class="slider measurements__input-group" :style="!step.selected.value.depthMeasurementRequired ? 'display:none' : ''">
        <div class="measurement__label">
            <strong class="attribute__label">DEPTH</strong>
        </div>
        <div class="scale"><span>{{ formatDepthToFraction(step.rulers[0].min.value) }} IN</span><span>{{ formatDepthToFraction(step.rulers[0].max.value) }} IN</span></div>
        <Ruler :step="step" :ruler="step.rulers[0]" @ruler-changed="onRulerChanged"></Ruler>
    </div>
</template>