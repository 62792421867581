<script setup>
    import { ref } from 'vue'
    import Image from '@/components/Image.vue';

    defineProps(['title', 'yt_id']);
    
    const modalShown = ref(false);
</script>

<template>
    <a class="btn-link btn-video" @click="modalShown = !modalShown">
        <Image src="icon-tutorial-dk.svg" /> {{ title }}
    </a>

    <BModal id="video-player" v-model="modalShown" centered hideFooter hideHeader>
        <div class="video-header">
            <div>
                <!-- <h5>Watch & Learn</h5>
                <h6>{{ title }}</h6> -->
            </div>
            <a @click="modalShown = !modalShown">✕ Close</a>
        </div>
        <div class="embed-wrapper">
            <iframe class="video-iframe" :src="`https://www.youtube.com/embed/${yt_id}?rel=0&amp;modestbranding=1`" frameborder="0" allowfullscreen=""></iframe>
        </div>
    </BModal>
</template>