<script setup>
    import { useData } from '@/composables/Data';
    import { computed } from 'vue';

    const data = useData();
    const baseImageUrl = 'https://everhem.blob.core.windows.net/images';

    const productTypeClass = computed(() => {
        return data.type === 'drapery' ? 'drapery-window' : 'standard-window';
    });

    const layers = Object.entries(data.rendering).map((kv) => kv[1]);

    function style(imageUrl) {
        return {'background-image': `url(${baseImageUrl}${imageUrl})`};
    }
</script>

<template>
    <div :class="['config--display-window', productTypeClass]">
        <template v-for="layer in layers" :key="layer.id">
            <div 
                v-if="layer.shouldShow()"
                :class="`config--display-items ${layer.id}-item`"
                :style="style(layer.getImageUrl())">
            </div>
        </template>
    </div>
</template>